import {
  // useEffect,
  useMemo,
  useState,
} from 'react';
import ISettings from 'components/Settings/ISettings';
import Data, { IDataReadResponse } from 'helpers/data';

const useSettings = () => {
  const { REACT_APP_API_URL } = process.env;
  const data = new Data<IDataReadResponse<ISettings>>(
    `${REACT_APP_API_URL}/settings`
  );
  const [settings, setSettings] = useState<any>();

  const getSettings = async () => {
    const settings = await data.read({
      // fields: ['key', 'value'],
      page: 1,
      limit: 100,
    });

    console.log({ settings });

    // const settingsList = {};

    // settings?.data?.items?.map(({ key, value }) =>
    //   Object.assign(settingsList, {
    //     [key]: value[0],
    //   })
    // );

    setSettings(settings?.data);
  };

  useMemo(() => {
    getSettings();
    // console.log({ settings });
  }, []);

  return settings;
};

export default useSettings;
