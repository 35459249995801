import { FC, Suspense, lazy, useEffect, useMemo } from 'react';
// import { Routes, Route } from 'react-router-dom';
import { Row, Col, Layout, Spin, theme, Badge, Avatar, Button } from 'antd';
import {
  // BellOutlined,
  LoadingOutlined,
  // EditOutlined,
  // DeleteOutlined,
  // UserOutlined,
} from '@ant-design/icons';
// import { v4 } from 'uuid';
import ErrorBoundary from 'components/ErrorBoundary';
// import AppRoute from 'components/AppRoute';
import { useAuthContext } from 'context/AuthContext';
// import { useAuth } from 'hooks/useAuth';
// import { useNavigate } from 'hooks/useNavigate';
import useSettings from 'hooks/useSettings';

// import Navigation from 'components/Navigation';

import { UserMenu } from 'components/Menu';
import useNotifications from 'hooks/useNotifications';
import Notifications from 'components/Notifications/Notifications';
// import useFirewall from 'hooks/useFirewall';

const Navigation = lazy(() => import('components/Navigation'));
const { Header, Footer, Content } = Layout;
const { useToken } = theme;

const App: FC = () => {
  // const { notification } = useNotifications();

  // notifcationsWorker;

  // notifcationsWorker.;

  // const location = useLocation();
  // const history = useHistory();
  // const [isLogedIn, setIsLogedIn] = useState<boolean>(false);
  // const [user, setUser] = useState<any>();
  const { isLogedIn } = useAuthContext();
  const settings = useSettings();
  const { token } = useToken();
  const getFullYear = () => {
    const date = new Date();

    return date.getFullYear();
  };

  // useEffect(() => {
  //   console.log({ isLogedIn });
  // }, [isLogedIn]);

  return (
    <ErrorBoundary>
      <Suspense
        fallback={
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            spinning={true}
            tip="Loading..."
          />
        }
      >
        {/* <Navigation> */}
        {/* <AuthContextProvider> */}
        <Layout>
          <>
            {console.log({ isLogedIn })}
            {isLogedIn && ( // TODO :: move it from here.
              <Header style={{ backgroundColor: token.colorPrimary }}>
                <Row>
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={6}
                    // span={4}
                    className="logo"
                  >
                    {settings?.siteName}
                  </Col>
                  <Col
                    xxl={20}
                    xl={20}
                    lg={20}
                    md={20}
                    sm={20}
                    xs={18}
                    // span={20}
                  >
                    <Row justify="end">
                      <Col xl={1} xs={8}>
                        {/* <Notifications refresh={notification} /> */}
                      </Col>
                      <Col xl={4} xs={16} className="userMenu">
                        <UserMenu />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Header>
            )}
            <Layout>
              <Content
                style={{
                  padding: '1em',
                }}
              >
                <Navigation />
              </Content>
            </Layout>
            <Footer style={{ textAlign: 'center' }}>
              &copy;&nbsp;{getFullYear()}&nbsp;{settings?.siteName}.&nbsp;All
              rights reserved.
            </Footer>
          </>
        </Layout>
        {/* </AuthContextProvider> */}
        {/* </Navigation> */}
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
