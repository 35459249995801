import { FC, useState, useEffect } from 'react';
import { Dropdown, type MenuProps } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { useAuthContext } from 'context/AuthContext';
import { useLogout } from 'hooks/useAuth';
import { useRouteContext } from 'context/RouteContext';

const UserMenu: FC = () => {
  const logout = useLogout();
  const { user } = useAuthContext();
  const { setPath, path } = useRouteContext();
  const [activeMenuItem, setActiveMenuItem] = useState<string>();

  const items: MenuProps['items'] = [
    {
      key: 'home',
      label: 'Home',
      onClick: () => setPath('/'),
    },
    {
      key: 'services',
      label: 'Services',
      onClick: () => setPath('/services'),
    },
    {
      key: 'orders',
      label: 'Orders',
      onClick: () => setPath('/orders'),
    },
    {
      key: 'pages',
      label: 'Pages',
      onClick: () => setPath('/pages'),
    },
    {
      key: 'feedback',
      label: 'Feedback',
      onClick: () => setPath('/feedback'),
    },
    {
      key: 'meta',
      label: 'Meta',
      onClick: () => setPath('/meta'),
    },
    {
      key: 'resources',
      label: 'Resources',
      onClick: () => setPath('/resources'),
    },
    {
      key: 'settings',
      label: 'Settings',
      onClick: () => setPath('/settings'),
    },
    {
      key: 'user-sign-out',
      label: 'Sign out',
      onClick: async () => await logout(),
    },
  ];

  useEffect(() => {
    console.log({ path });

    setActiveMenuItem(path?.split('/')[1]);
  }, []);

  return (
    <Dropdown
      menu={{
        items,
        selectable: true,
        // defaultSelectedKeys: ['home'],
        onClick: (e) => setActiveMenuItem(e.key),
        activeKey: activeMenuItem,
      }}
      placement="bottomRight"
      arrow
    >
      <a
        href="/"
        className="ant-dropdown-link user-profile-menu-link"
        onClick={(e) => e.preventDefault()}
      >
        {/* Welcome aboard, */}
        <UserOutlined /> Hello admin &nbsp;
        {user?.lastname}
        &nbsp;
        <DownOutlined />
        {/* <Avatar
                            src={
                              <Image
                                src={'/default-avatar.png'}
                                // fallback={'/default-avatar.png'} it doesn't work :/
                                preview={false}
                              />
                            }
                          /> */}
      </a>
    </Dropdown>
  );
};

export { UserMenu };
