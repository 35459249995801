// import Cookies from "js-cookie";
// import AppError from 'components/AppError';

// TODO :: cache

interface IData {
  url: string;
  params: {
    mode: RequestMode;
    credentials: RequestCredentials;
    headers: { [key: string]: string };
    method?: string;
    body?: string | FormData;
  };
}

export interface IDataCreateOptions<S> {
  body: Omit<S, '_id'>;
}

type KeysOfType<T> = { [P in keyof T]: P }[keyof T];

// type KeysOfType<T> = { [P in keyof T]: P }[keyof T];

// type OptionsFlags<Type> = {
//   [Property in keyof Type]?: Type[Property];
// };

interface IDataReadOptions {
  page?: number;
  limit?: number;
  sort?: string;
  countTotal?: boolean;
}

interface IDataReadFilter<S> {
  // where?: OptionsFlags<S>;
  where?: any;
  fields?: KeysOfType<S>[];
}

export interface IDataUpdateOptions<S> {
  body: S;
}

export interface IDataDeleteOptions {
  where?: any;
}

interface IUpload {
  progress: Function;
  file: File;
  where: {
    path: string;
  };
}

export interface IDataReadResponse<S> {
  status: string;
  data: {
    items: S[];
    pagination: {
      total?: number;
      pageSize: number;
      current: number;
    };
  };
}

class Data<S> implements IData {
  url: string;
  params: {
    mode: RequestMode;
    credentials: RequestCredentials;
    headers: { [key: string]: string };
    method?: string;
    body?: string | FormData;
  };

  constructor(url: string) {
    // const token = Cookies.get("user_token");

    // console.log(token);

    // const authToken = Cookies.get('authToken') || '';
    this.url = url;
    this.params = {
      mode: 'cors',
      credentials: 'include', // Don't forget to specify this if you need cookies
      headers: {
        //   Authorization: 'Bearer ' + authToken,
        'Content-Type': 'application/json',
        Cookie: `plants_store_session_id=33de69ec-1db6-4c98-b07c-e6a7b00e7618`,
      },
    };
  }

  async upload(data: IUpload) {
    delete this.params.headers['Content-Type'];

    const { file, where, progress } = data;

    const formData = new FormData();

    // Object.entries(files).map(([key, value]) => formData.append(key, value));

    formData.append('file', file);

    // console.log(formData);

    // console.log(where);

    // this.params.method = 'POST';
    // this.params.body = formData;

    // return this._fetchData();

    const xhr = new XMLHttpRequest();
    await new Promise((resolve) => {
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          console.log('upload progress:', event.loaded / event.total);
          // uploadProgress.value = event.loaded / event.total;

          progress(((event.loaded / event.total) * 100).toFixed());
        }
      });
      // xhr.addEventListener('progress', (event) => {
      //   if (event.lengthComputable) {
      //     console.log('download progress:', event.loaded / event.total);
      //     downloadProgress.value = event.loaded / event.total;
      //   }
      // });
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('POST', `${this.url}?${this._queryParams(where)}`, true);
      // xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.withCredentials = true;
      xhr.send(formData);
    });
    // console.log('success:', success);
  }

  create(options: IDataCreateOptions<S>) {
    this.params.method = 'POST';

    // if (options) {
    //   this.params.body = JSON.stringify(options.body);
    // }

    this.params.body = JSON.stringify(options.body);

    const obj: Partial<IDataCreateOptions<S>> = options;

    delete obj.body;

    return this._fetchData(options);
  }

  read(options: IDataReadOptions & IDataReadFilter<any>): Promise<S> {
    this.params.method = 'GET';

    // console.log(options);
    // console.log(JSON.stringify(options));

    const { fields, where, ...rest } = options;

    // let opts = options?.where._id;

    // console.log(opts);

    return this._fetchData({ fields, where, ...rest });
  }

  update(options: IDataUpdateOptions<S>) {
    // console.log(options.body);

    this.params.method = 'PATCH';
    // this.params.body = JSON.stringify(options.body);

    // delete options.body;

    this.params.body = JSON.stringify(options.body);

    const obj: Partial<IDataUpdateOptions<S>> = options;

    delete obj.body;

    return this._fetchData(options);
  }

  delete(options?: IDataDeleteOptions) {
    this.params.method = 'DELETE';
    if (options) {
      const obj: Partial<IDataDeleteOptions> = options;
      if (options?.where) {
        this.params.body = JSON.stringify(options?.where);
        delete obj.where;
      }
    }

    return this._fetchData(options || {});
  }

  // private _instanceOf<S>(data: any): data is S {
  //   return data;
  // }

  private async _fetchData<T extends object>(options: T) {
    try {
      let url = this.url;

      // console.log(url);
      // console.log({ options });

      if (
        options &&
        Object.keys(options).length !== 0 &&
        options.constructor === Object
      ) {
        // console.log(options);

        // if (this._instanceOf<IDataUpdateOptions<S>>(options)) {
        //   // ????????
        //   // TODO :: move to update method???
        //   this.params.body = JSON.stringify(options.body);

        //   delete options.body;

        //   // console.log(options['_id']);
        // }

        url = `${this.url}?${this._queryParams(options)}`;
      }

      // console.log('Request sending...');

      // console.log({ url });
      // console.log(this.params);

      const response = await fetch(url, this.params);
      const data = await response.json();

      // console.log({ response });

      // If have got an 500 error from server throw an exception
      if (data.status === 'error' && data.statusCode === 500) {
        // throw new AppError(data);
        console.error('ERROR');
      }

      return data;
    } catch (err: any) {
      console.error('ERROR:', err);
      // throw new AppError(err.message);
    }
  }

  private _queryParams<T extends object>(options: T) {
    // let opts = options as any;
    // let obj = Object.assign({}, opts.where);
    // for (const [key, value] of Object.entries(obj)) {
    //   console.log(`${key}: ${value}`);
    // }
    // // console.log('OPTIONS', Object.entries(obj).keys);
    // console.log('OPTIONS', JSON.stringify(options));

    // params = {
    //   key: value,
    //   key1: value1,
    //   key2: value2,
    // }

    // Object.entries(options).map((value, index))

    const entries = Object.entries(options).filter(([key, value]) => value);

    let opts = entries.map(([key, value]) => {
      // console.log({ key });
      // console.log({ value });

      // console.log(typeof value);

      // opt += `${key}=${value}`;

      // if (typeof value !== "undefined") {
      if (
        typeof value !== 'object' ||
        Array.isArray(value)
        // || typeof value[Object.keys(value)[0]] == "string"
      ) {
        return `${key}=${value}`;
      }

      // console.log(typeof value[Object.keys(value)[0]]);

      // if (typeof value[Object.keys(value)[0]] == "string") {
      //   console.log(value);
      //   return `${key}=${value[Object.keys(value)[0]]}`;
      // }

      let opt = `${key}=[`;

      // for (const [key, val] of Object.entries(value)) {
      let values = Object.entries(value).map(([key, val]) => {
        // console.log(`${key}: ${val}`);

        if (typeof val == 'string') {
          return `${key}:${val}`;
        }

        let v = `${key}`;

        for (const [skey, sval] of Object.entries(val as any)) {
          v += `[${skey}]:[${sval}]`;
          // console.log(`${skey}: ${sval}`);
        }

        return v;
      });

      return opt + values.join(',') + ']';
      // }
      // return opt;
      // }
      // }
    });

    // console.log("TEST", opts);

    return opts.join('&');

    // return Object.keys(options) // [key, key1, key2]
    //   .reduce<string[]>(function (result, key) {
    //     // (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    //     result.push(`${key}=${options[key]}`);

    //     return result;
    //   }, [])
    //   .join('&');
  }
}

export default Data;
