import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { AuthContextProvider } from 'context/AuthContext';
import { RouteContextProvider } from 'context/RouteContext';
import Firewall from 'components/Firewall';
import firewallRules from './firewall.json';
import './assets/css/index.css';
import App from './App';
// import { register } from './serviceWorkerRegistartion';

const container = document.getElementById('app')!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <Router basename={process.env.PUBLIC_URL}>
  <RouteContextProvider>
    <AuthContextProvider>
      <Firewall config={firewallRules}>
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </Firewall>
    </AuthContextProvider>
  </RouteContextProvider>
  // </Router>
);

// register();

// if ('serviceWorker' in navigator) {
//   // Register a service worker hosted at the root of the
//   // site using the default scope.
//   navigator.serviceWorker
//     .register(
//       new URL(
//         './components/Notifications/notifications-worker.ts',
//         import.meta.url
//       )
//     )
//     .then(
//       (registration) => {
//         console.log('Service worker registration succeeded:', registration);
//       },
//       /*catch*/ (error) => {
//         console.error(`Service worker registration failed: ${error}`);
//       }
//     );
// } else {
//   console.error('Service workers are not supported.');
// }

// async function initServiceWorker() {
//   if ('serviceWorker' in navigator) {
//     const test = await navigator.serviceWorker.register(
//       new URL(
//         './components/Notifications/notifications-worker.ts',
//         import.meta.url
//       )
//     );

//     // navigator.serviceWorker.startMessages();
//     // await swRegistration.update();

//     await test.update();
//   } else {
//     console.warn('Service worker is not supported');
//   }
// }

// initServiceWorker();
